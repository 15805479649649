var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"stock-medicine-view"},[_c('layout-toolbar',{staticClass:"post-header-right",attrs:{"type":"flex","justify":"end"}},[_c('el-col',{attrs:{"span":4.5}},[_c('er-input',{attrs:{"inputType":"search","size":"mini","suffix-icon":"el-icon-search","placeholder":_vm.$t('Comn_search')},on:{"change":_vm.handelSearchInput},model:{value:(_vm.searchmedicine),callback:function ($$v) {_vm.searchmedicine=$$v},expression:"searchmedicine"}})],1),_vm._v(" "),_c('er-action-button',{attrs:{"type":'solid',"disabled":_vm.isReadOnly},on:{"action-btn-click":function($event){return _vm.handleAddMedicine()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{staticStyle:{"margin-top":"4px"},attrs:{"src":require("@/assets/button/add.svg")}})]},proxy:true},{key:"label",fn:function(){return [_c('p',{staticStyle:{"text-transform":"uppercase"}},[_vm._v("\n          "+_vm._s(_vm.$t("Stock_add_med"))+"\n        ")])]},proxy:true}])})],1),_vm._v(" "),(_vm.loading)?_c('Loader'):_vm._e(),_vm._v(" "),(!_vm.loading || _vm.tableDataArray.length > 0)?_c('el-row',[_c('er-data-tables',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.$i18n.locale,ref:"medicinefeedtable",attrs:{"uniqueId":"medicine-feed-table","type":"white-header-table","element-loading-background":"white","table-data":_vm.tableDataArray,"columns":_vm.tableColumns,"el-table-props":_vm.tableProps,"pagination-props":_vm.$constants.table['pagination-props'],"actionColumn":false,"row-key":"_id","expand-column":true,"searchOnTableData":_vm.searchmedicine},on:{"expand-change":_vm.handleExpandChange},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"name","withoutHighlightStrings":row.data.name}})]}},{key:"stock_quantity",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"stock_quantity","withoutHighlightStrings":((_vm.ftm__digitPrecision(
            row.data.stock_quantity,
            2
          )) + " " + (_vm.getUnits(row.data.units)))}})]}},{key:"last_purchased_quantity",fn:function(ref){
          var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"last_purchased_quantity","withoutHighlightStrings":((_vm.ftm__formatEmpty(
            _vm.ftm__digitPrecision(row.data.last_purchased_quantity, 2)
          )) + "   " + (row.data.last_purchased_quantity ? _vm.getUnits(row.data.units) : ''))}})]}},{key:"last_updated_at",fn:function(ref){
          var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.lastUpdatedDate(row.data.last_updated_at))+"\n      ")]}},{key:"expandColumn",fn:function(ref){
          var row = ref.row;
return [_c('er-data-tables',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.expandloading === row.data._id),expression:"expandloading === row.data._id"}],attrs:{"tableData":_vm.getExpandedTableData(row.data),"columns":_vm.expandedTableColumns,"el-table-props":_vm.tableProps_expand_table,"action-column":false,"unique-id":"medicine-inner-table","type":"white-header-table-inner","layout":"table"},scopedSlots:_vm._u([{key:"number_of_units",fn:function(ref){
          var row1 = ref.row;
return [_vm._v("\n            "+_vm._s(row1.data.number_of_units)+"\n          ")]}},{key:"unit_quantity",fn:function(ref){
          var row1 = ref.row;
return [_vm._v("\n            "+_vm._s(row1.data.unit_quantity)+" "+_vm._s(_vm.getUnits(row1.data.units))+"\n          ")]}},{key:"quantity_purchased",fn:function(ref){
          var row1 = ref.row;
return [_vm._v("\n            "+_vm._s(row1.data.quantity_purchased)+" "+_vm._s(_vm.getUnits(row1.data.units))+"\n          ")]}},{key:"vendor_name",fn:function(ref){
          var row1 = ref.row;
return [_vm._v("\n            "+_vm._s(typeof _vm.getObjVendors[row1.data.vendor_id] !== "undefined"
                ? _vm.getObjVendors[row1.data.vendor_id].name
                : "--")+"\n          ")]}},{key:"date",fn:function(ref){
                var row1 = ref.row;
return [_vm._v("\n            "+_vm._s(_vm.lastUpdatedDate(row1.data.date))+"\n          ")]}},{key:"actions",fn:function(ref){
                var row1 = ref.row;
return [_c('er-button',{attrs:{"type":"text","size":"mini","btnType":"edit","showLabel":true,"showIcon":true},on:{"click":function($event){return _vm.handleEditMedicineObj(row1.data)}}}),_vm._v(" "),_c('el-divider',{attrs:{"direction":"vertical"}}),_vm._v(" "),_c('er-button',{attrs:{"type":"text","size":"mini","btnType":"delete","showLabel":true,"showIcon":true},on:{"click":function($event){return _vm.handleDeleteMedicineObj(row1.data)}}})]}}],null,true)})]}}],null,false,22738111)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }