var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-dialog',{staticClass:"medicine-add-dialog",attrs:{"title":((_vm.actionToPerform === 'edit' ? _vm.$t('Comn_edit') : _vm.$t('Comn_create')) + " " + (_vm.$t('Stock_med_minerals_stock'))),"visible":_vm.dialogVisible,"close-on-click-modal":false,"top":"25vh","width":"80%","custom-class":"medicine-add-dialog-box"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"beforeClose":function($event){return _vm.handleCloseDialog('close')},"open":_vm.handleDialogOpen}},[_c('el-row',[_c('el-row',{staticStyle:{"margin-bottom":"10px"}},[_c('el-date-picker',{attrs:{"clearable":false,"size":"mini","type":"date","format":_vm.upm__getFormatDateString,"picker-options":_vm.pickerOptions,"value-format":"yyyy-MM-dd"},on:{"change":_vm.handelDate},model:{value:(_vm.dateOfPurchase),callback:function ($$v) {_vm.dateOfPurchase=$$v},expression:"dateOfPurchase"}})],1),_vm._v(" "),_c('el-row',[_c('er-data-tables',{attrs:{"uniqueId":"medicine_Add_Dialog","type":"white-header-table","table-data":_vm.tableDataArr,"el-table-props":_vm.tableProps,"columns":_vm.getColumns(_vm.tableColumns),"actionColumn":false,"layout":"table"},scopedSlots:_vm._u([{key:"resource_name",fn:function(ref){
  var row = ref.row;
return [_c('er-select',{attrs:{"size":"mini","placeholder":_vm.$t('Stock_med_select'),"disabled":_vm.actionToPerform === 'edit' ? true : false},on:{"change":function($event){return _vm.handleChangeResource(row.data)}},model:{value:(row.data.resource_id),callback:function ($$v) {_vm.$set(row.data, "resource_id", $$v)},expression:"row.data.resource_id"}},_vm._l((_vm.getMedicineObjResources),function(resource,resource_id){return _c('el-option',{key:resource_id,attrs:{"label":resource.name,"value":resource_id}})}),1)]}},{key:"number_of_units",fn:function(ref){
  var row = ref.row;
return [_c('el-input-number',{attrs:{"size":"small","min":1,"controls":false,"placeholder":"0"},on:{"change":function($event){return _vm.handleChangeInNoOfUnits(row.data)}},model:{value:(row.data.number_of_units),callback:function ($$v) {_vm.$set(row.data, "number_of_units", $$v)},expression:"row.data.number_of_units"}})]}},{key:"unit_quantity",fn:function(ref){
  var row = ref.row;
return [_c('el-input-number',{attrs:{"size":"small","min":1,"controls":false},on:{"change":function($event){return _vm.handleChangeInQuantity(row.data)}},model:{value:(row.data.unit_quantity),callback:function ($$v) {_vm.$set(row.data, "unit_quantity", $$v)},expression:"row.data.unit_quantity"}})]}},{key:"total_price",fn:function(ref){
  var row = ref.row;
return [_vm._v(_vm._s(_vm.ftm__digitPrecision(row.data.total_price, 2)))]}},{key:"vendor_id",fn:function(ref){
  var row = ref.row;
return [_c('er-select',{attrs:{"size":"mini","placeholder":_vm.$t('Resource_select_vendors')},model:{value:(row.data.vendor_id),callback:function ($$v) {_vm.$set(row.data, "vendor_id", $$v)},expression:"row.data.vendor_id"}},_vm._l((_vm.getObjVendors),function(vendor,vendor_id){return _c('el-option',{key:vendor_id,attrs:{"label":vendor.name,"value":vendor_id}})}),1)]}},{key:"units",fn:function(ref){
  var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.getUnits(row.data.units))+"\n        ")]}},{key:"actions",fn:function(ref){
  var row = ref.row;
return [(row.$index === _vm.tableDataLength - 1)?[_c('er-button',{attrs:{"icon":"el-icon-plus","size":"mini","circle":""},on:{"click":function($event){return _vm.handleAddRow()}}})]:[_c('er-button',{attrs:{"icon":"el-icon-minus","size":"mini","circle":""},on:{"click":function($event){return _vm.handleDeleteRow(row.$index)}}})]]}}])},[_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_vm._v(" "),_c('el-row',{staticStyle:{"margin":"10px","text-align":"right"},attrs:{"slot":"append"},slot:"append"})],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{staticStyle:{"display":"flex","align-items":"flex-start","justify-content":"flex-start"}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.actionToPerform === 'add'),expression:"actionToPerform === 'add'"}],staticClass:"footer__content"},[_vm._v("\n        "+_vm._s(_vm.$t("Stock_total_stock_abbr"))+"\n      ")])]),_vm._v(" "),_c('div',{staticStyle:{"display":"flex"}},[_c('er-button',{attrs:{"size":"mini","btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.loading},on:{"click":function($event){return _vm.handleSaveMedicine()}}}),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","btnType":"cancel","showLabel":true},on:{"click":function($event){return _vm.handleCloseDialog('close')}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }